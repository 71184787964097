<template>
  <div class="uploadresource">
    <div class="uplodres_center">
      <header>
        <p>资源上传</p>
      </header>
      <main>
        <el-form ref="uploadResRef" :model="uploadresForm" label-width="80px" :rules="uploadRules">
          <el-form-item label="资源名称" prop="resouceTitle">
            <el-input v-model="uploadresForm.resouceTitle" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="资源类型" prop="resourceType">
            <el-select v-model="uploadresForm.resourceType" size="mini">
              <el-option v-for="item in restypedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科类型" prop="subjectTypeIdList">
            <el-select v-model="uploadresForm.subjectTypeIdList" @change="changeshuject" size="mini">
              <el-option v-for="item in subjectypedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科" prop="subjectIdList">
            <el-select v-model="uploadresForm.subjectIdList" @click.native="subjectinfo" ref="subjectInfoRef" size="mini">
              <el-option v-for="item in subjectdata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学段" prop="periodIdList">
            <el-select v-model="uploadresForm.periodIdList" @change="changeperiod" multiple size="mini">
              <el-option label="全选" value="all"></el-option>
              <el-option v-for="item in perioddata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级" prop="gradeIdList">
            <el-select
              v-model="uploadresForm.gradeIdList"
              multiple
              @change="selectAll"
              @click.native="gradeinfo"
              ref="gradeInfoRef"
              size="mini"
            >
              <el-option label="全选" value="all"></el-option>
              <el-option v-for="item in gradedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="资源简介" prop="content">
            <el-input type="textarea" v-model="uploadresForm.content" :rows="5" style="width: 450px;"></el-input>
          </el-form-item>
          <!-- <el-form-item label="文件类型" prop="uptype">
            <el-select v-model="uploadresForm.uptype" placeholder="请选择" size="mini">
              <el-option v-for="item in uptypedata" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>-->
          <el-form-item label="上传资源" prop="filesss">
            <!-- accept=".mp4" -->
            <el-upload
              class="upload-demo"
              action="#"
              :auto-upload="false"
              :on-change="hourfileChange"
              :limit="1"
              :file-list="fileList"
              :on-exceed="handleExceed"
              :before-remove="beforeRemove"
              style="width:450px"
            >
              <el-button size="mini" type="primary">上传资源</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </main>
      <footer>
        <el-button
          size="mini"
          type="primary"
          @click="comfrom"
          v-loading.fullscreen.lock="meansloading"
          element-loading-text="正在上传中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >完成</el-button>
        <el-button size="mini" @click="cencle">取消</el-button>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // loading
      meansloading: false,
      // 上传资源的表单
      uploadresForm: {
        resouceTitle: null,
        resourceType: null,
        subjectTypeIdList: null,
        subjectIdList: null,
        periodIdList: [],
        gradeIdList: [],
        content: null,
        // uptype: null,
      },
      // 上传类型

      // uptypedata: [
      //   {
      //     value: 0,
      //     label: "图片",
      //   },
      //   {
      //     value: 1,
      //     label: "视频",
      //   },
      //   {
      //     value: 2,
      //     label: "文档",
      //   },
      //   {
      //     value: 3,
      //     label: "音频",
      //   },
      //   {
      //     value: 4,
      //     label: "压缩包",
      //   },
      //   {
      //     value: 5,
      //     label: "其他",
      //   },
      // ],
      // 文件
      file: null,
      // 文件列表
      fileList: [],
      // 资源类型
      restypedata: null,
      //学科类型
      subjectypedata: null,
      // 学科
      subjectdata: null,
      // 学段
      perioddata: null,
      // 年级
      gradedata: null,

      //表单校验
      uploadRules: {
        resouceTitle: [
          { required: true, message: "请输入标题", trigger: "blur" },
        ],
        resourceType: [
          { required: true, message: "请选择资源类型", trigger: "change" },
        ],
        subjectTypeIdList: [
          { required: true, message: "请选择学科类型", trigger: "change" },
        ],
        subjectIdList: [
          { required: true, message: "请选择学科", trigger: "change" },
        ],
        periodIdList: [
          { required: true, message: "请选择学段", trigger: "change" },
        ],
        gradeIdList: [
          { required: true, message: "请选择年级", trigger: "change" },
        ],
        // uptype: [
        //   { required: true, message: "请选择文件类型", trigger: "change" },
        // ],
      },
    };
  },
  created() {
    this.queryResourceType(); // 获取资源类型
    this.querySubjectType(); //获取课程类型
    // this.querySubject(0); //获取学科信息
    this.queryPeriod(); //获取学段
    // this.queryGrade(0); //获取年级信息
    // console.log(this.$route.query.id, "adsf");
    if (this.$route.query.id !== undefined) {
      this.queryResorceDetailById(this.$route.query.id);
    }
  },
  methods: {
    // 编辑资源列表
    async queryResorceDetailById(ids) {
      console.log(this.$route.query.id, "编辑资源");
      let from = new FormData();
      from.append("resourceId", ids);
      const { data, status } = await this.$Http.queryResorceDetailById(from);
      if (status == 200) {
        // console.log(data, status, "获取资源列表");
        let a = this.uploadresForm;
        let b = data;
        a.resouceTitle = b.resourceTitle;
        a.resourceType = b.resourceType.id;
        a.content = b.content;
        // a.uptype = b.uploadType;
        //获取学科信息
        a.subjectTypeIdList = b.subjectType[0].id;
        this.querySubject(b.subjectType[0].id).then(() => {
          a.subjectIdList = b.subject[0].id;
        });
        // 获取年级
        let aa = [];
        for (let x = 0; x < b.period.length; x++) {
          aa.push(b.period[x].id);
        }
        a.periodIdList = aa;
        this.queryGrade(aa).then(() => {
          let aaa = [];
          for (let y = 0; y < b.grade.length; y++) {
            aaa.push(b.grade[y].id);
          }
          a.gradeIdList = aaa;
        });

        //获取资源
        let bb = [
          {
            name: b.resource[0].resouceName,
            url: b.resource[0].resouceUrl,
            id: b.resource[0].resouceId,
          },
        ];
        this.fileList = bb;
      }
    },
    //完成资源上传
    async saveResource(id) {
      console.log(id, "文件id");
      // console.log(this.uploadresForm, "uploadresForm");
      let editId = null;
      if (this.$route.query.id !== undefined) {
        editId = this.$route.query.id - 0;
      }
      const { data, status } = await this.$Http.saveResource({
        // ...this.uploadresForm,
        resouceTitle: this.uploadresForm.resouceTitle, //资源标题
        resourceType: this.uploadresForm.resourceType, //资源类型
        subjectTypeIdList: [this.uploadresForm.subjectTypeIdList], //学科类型集合
        subjectIdList: [this.uploadresForm.subjectIdList], //学科id集合
        periodIdList: this.uploadresForm.periodIdList, //学段集合
        gradeIdList: this.uploadresForm.gradeIdList, //年级集合
        content: this.uploadresForm.content, //简介
        resouceIdList: [id], //添加的资源的集合
        id: editId, //id（修改时传入,添加不传）
        // pictureId: pictureId, //图片id
      });
      if (status == 200) {
        console.log(data, status, "保存资源");
        this.meansloading = false;
        this.$router.push({
          name: "12-1",
        });
      } else {
        this.meansloading = false;
      }
    },
    // 获取资源类型
    async queryResourceType() {
      await this.$Http.queryResourceType().then((res) => {
        // console.log(res, "获取资源类型");
        if (res.status == 200) {
          this.restypedata = res.data;
        }
      });
    },
    // 获取课程类型
    async querySubjectType() {
      await this.$Http.querySubjectType().then((res) => {
        // console.log(res, "获取课程类型");
        if (res.status == 200) {
          this.subjectypedata = res.data;
        }
      });
    },
    // 获取学科信息
    async querySubject(id) {
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.querySubject({
        idList: ids,
      });
      if (status == 200) {
        // console.log(data, status, "获取学科");
        this.subjectdata = data;
      }
    },
    // 获取学段
    async queryPeriod() {
      await this.$Http.queryPeriod().then((res) => {
        // console.log(res, "获取学段");
        if (res.status == 200) {
          this.perioddata = res.data;
        }
      });
    },
    // 获取年级信息
    async queryGrade(id) {
      let ids = null;
      if (id !== 0) {
        ids = id;
      }
      const { data, status } = await this.$Http.queryGrade({
        idList: ids,
      });
      if (status == 200) {
        // console.log(data, status, "获取年级信息");
        this.gradedata = data;
      }
    },
    // 学科类型的值得变化
    changeshuject(val) {
      this.querySubject(val);
    },
    // 学段的值得变化
    changeperiod(val) {
      if (val.length == 0) {
        this.gradedata = null;
        return;
      }
      if (val.length > 1) {
        val.sort((a, b) => {
          return a - b;
        });
      }
      let flag = false;
      for (var key of val) {
        //如果有all
        if (key == "all") {
          flag = true;
        }
      }

      if (flag) {
        //先置空数组
        this.uploadresForm.periodIdList = [];
        //所有的选项push到v-model的value的数组
        this.perioddata.forEach((item) => {
          this.uploadresForm.periodIdList.push(item.id);
        });
      }
      this.queryGrade(this.uploadresForm.periodIdList);
    },
    //
    selectAll(val) {
      // console.log(val, "val");
      if (val.length > 1) {
        val.sort((a, b) => {
          return a - b;
        });
      }
      let flag = false;
      for (var key of val) {
        //如果有all
        if (key == "all") {
          flag = true;
        }
      }
      if (flag) {
        //先置空数组
        this.uploadresForm.gradeIdList=[]
        // this.infoForm.grade = [];
        //所有的选项push到v-model的value的数组
        this.gradedata.forEach((item) => {
          this.uploadresForm.gradeIdList.push(item.id);
        });
      }
    },
    // 警告年级没有学段
    gradeinfo() {
      // console.log(this.meansHour, "焦点获取");
      if (this.gradedata == null || this.gradedata.length == 0) {
        this.$refs.gradeInfoRef.blur();
        this.$message({
          type: "warning",
          message: "请先选择学段信息",
        });
      }
    },
    // 警告学科没有学科类型
    subjectinfo() {
      // console.log(this.meansHour, "焦点获取");
      if (this.subjectdata == null || this.subjectdata.length == 0) {
        this.$refs.subjectInfoRef.blur();
        this.$message({
          type: "warning",
          message: "请先选择学科类型信息",
        });
      }
    },
    // 文件上传
    hourfileChange(file, filelist) {
      this.file = file;
      this.fileList = filelist;
      console.log(file, filelist, "上传资源");
    },
    // 文件上传限制
    handleExceed(files, fileList) {
      console.log(files, fileList, "文件上传限制1");
      console.log(this.file, this.fileList, "文件上传限制2");
      this.$message.warning(
        "系统目前仅支持上传一个资源请先删除当前已有资源，再进行新资源的上传"
        // `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
        //   files.length + fileList.length
        // } 个文件`
      );
    },
    // 文件删除是的钩子
    beforeRemove(file) {
      this.fileList = [];
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //完成
    comfrom() {
      console.log(this.file, this.fileList, "完成提交");
      let that = this;
      this.$refs["uploadResRef"].validate((valid) => {
        if (valid) {
          console.log(this.file == null && this.fileList.length == 0, "k1");
          if (this.file == null && this.fileList.length == 0) {
            this.$message.error("请选择要上传的资源");
          } else {
            console.log(this.file == null && this.fileList.length !== 0, "k2");
            if (this.file == null && this.fileList.length !== 0) {
              this.$confirm("确定资源建立完成并退出?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.saveResource(this.fileList[0].id);
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消删除",
                  });
                });
            } else if (this.file !== null && this.fileList.length !== 0) {
              console.log(
                this.file !== null && this.fileList.length == 0,
                "k3"
              );
              this.$confirm("确定资源建立完成并退出?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(async () => {
                  // let suffix = this.file.name.substring(
                  //   this.file.name.lastIndexOf(".") + 1
                  // );
                  // let fileType = this.uploadresForm.uptype;
                  that.meansloading = true;
                  let from = new FormData();
                  from.append("file", this.file.raw);
                  // from.append("fileType", this.uploadresForm.uptype); //文件类型 0-图片 1-视频 2-文档 3-音频 4-压缩包 5-其他
                  const { data, status } = await this.$Http.uploadfile(from);

                  if (status == 200) {
                    // console.log(data, status, "上传资源");
                    this.saveResource(data.id);
                  } else {
                    that.meansloading = false;
                  }
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消",
                  });
                });
            }
          }
        } else {
          return false;
        }
      });
    },
    cencle() {
      this.$confirm("是否确认退出", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push({
            path: "/resource",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
  watch: {
    "uploadresForm.periodIdList": {
      handler(newval, oldval) {
        // console.log(newval, oldval, "监听学段");
        if (newval.length < oldval.length) {
          let set1 = new Set(oldval);
          let set2 = new Set(newval);
          let diffrence = new Set([...set1].filter((x) => !set2.has(x)));
          let num = [...diffrence];
          let aData = this.uploadresForm.gradeIdList;
          let astart = [];
          aData.map((val, i) => {
            if (num == 1 && val == 1) {
              astart.push(i);
            } else if (num == 2 && val > 1 && val < 8) {
              astart.push(i);
            } else if (num == 3 && val > 7 && val < 11) {
              astart.push(i);
            } else if (num == 4 && val > 10 && val < 14) {
              astart.push(i);
            } else if (num == 5 && val > 13 && val < 17) {
              astart.push(i);
            }
          });
          aData.splice(astart[0], astart.length);
        }
      },
      deep: true,
    },
    "uploadresForm.gradeIdList": {
      handler(newval, oldval) {
        // console.log(newval, oldval, "监听年级");
        // console.log(this.uploadresForm.period, "监听xued");
        // if (newval.length == 0 && oldval == null) {
        //   return;
        // }
        if (newval.length < oldval.length) {
          let set1 = new Set(oldval);
          let set2 = new Set(newval);
          let diffrence = new Set([...set1].filter((x) => !set2.has(x)));
          let num = [...diffrence];
          let aData = this.uploadresForm.periodIdList;
          let aend = [];
          newval.map((val, i) => {
            if (num == 1 && val == 1) {
              aend.push(i);
            } else if (num > 1 && num < 8 && val > 1 && val < 8) {
              aend.push(i);
            } else if (num > 7 && num < 11 && val > 7 && val < 11) {
              aend.push(i);
            } else if (num > 10 && num < 14 && val > 10 && val < 14) {
              aend.push(i);
            } else if (num > 13 && num < 17 && val > 13 && val < 17) {
              aend.push(i);
            }
          });
          if (aend.length == 0) {
            let aMessage = null;
            if (num == 1) {
              aMessage = "您已经移除了幼儿教育的最后一个年级";
            } else if (num > 1 && num < 8) {
              aMessage = "您已经移除了小学的最后一个年级";
            } else if (num > 7 && num < 11) {
              aMessage = "您已经移除了初中的最后一个年级";
            } else if (num > 10 && num < 14) {
              aMessage = "您已经移除了高中的最后一个年级";
            } else if (num > 13 && num < 17) {
              aMessage = "您已经移除了职教的最后一个年级";
            }
            this.$message.warning(aMessage);
            aData.map((val, i) => {
              if (num == 1 && val == 1) {
                aData.splice(i, 1);
              } else if (num > 1 && num < 8 && val == 2) {
                aData.splice(i, 1);
              } else if (num > 7 && num < 11 && val == 3) {
                aData.splice(i, 1);
              } else if (num > 10 && num < 14 && val == 4) {
                aData.splice(i, 1);
              } else if (num > 13 && num < 17 && val == 5) {
                aData.splice(i, 1);
              }
            });
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.uploadresource {
  .uplodres_center {
    background-color: #fff;
    header {
      padding: 25px 30px;
      border-bottom: 1px solid #d9d9d9;
      p {
        // width: 72px;
        // height: 24px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 600;
        color: #141516;
        // line-height: 21px;
      }
    }
    main {
      padding: 25px;
      .el-input {
        width: 450px;
      }
      .el-select {
        width: 450px;
      }
    }
    footer {
      padding: 0 0 20px 425px;
    }
  }
}
</style>